import React, { useEffect, useState } from "react"
import { Alert, Button, ButtonToolbar, Content, FlexboxGrid, Header, Input, Modal, Radio, RadioGroup, SelectPicker } from "rsuite"
import settingsIcon from "../../assets/svgs/settingsIcon.svg"
import BoxPanel from "src/components/AddRollPanel/BoxPanel/BoxPanel"
import "./AddRoll.scss"
import { retargetingCamp } from "src/components/AddRollPanel/BoxPanel/api-panel"
import channelSvg from "../../assets/svgs/channel.svg"
import chooseScheduleSvg from "../../assets/svgs/chooseSchedule.svg"
import webSvg from "../../assets/svgs/webForCampaign.svg"
import adgroupSvg from "../../assets/svgs/adGroup.svg"
import budget from "../../assets/svgs/budgetIcon.svg"
import { useHistory } from "react-router-dom"
import { useProviderId } from "src/common/constants"
import { WebAdsType } from "src/common/utils/types"

interface selectedRowsImgType {
  image_url: string
  height: number
  width: number
  adroll_ad_eid: string
}

interface selectedRowsAudType {
  segment_id: string
}

interface suggestionType {
  eid: string
}

const AddRoll = (props: any) => {
  const { selectedCardGoal } = props

  const today = new Date()
  const nextFiveDays = new Date(today)
  nextFiveDays.setDate(today.getDate() + 6)

  const nextFiveDaysFormate = nextFiveDays.toISOString().split("T")[0] + "T" + new Date().toLocaleTimeString(undefined, { hour12: false })

  const [inputNumberValue, setInputNumberValue] = useState(50)
  const [inputValue, setInputValue] = useState("AdGroup")
  const [selectedRowsImg, setSelectedRowsImg] = useState<selectedRowsImgType[]>([])
  const [loading, setLoading] = useState(false)
  const [service, setService] = useState("")
  const [selectedRowsAud, setSelectedRowsAud] = useState<selectedRowsAudType[]>([])
  const [bidInput, setBidInput] = useState()
  const [selectedValue, setSelectedValue] = useState("Impressions")
  const [bidStrategyValue, setBidStrategyValue] = useState("")
  const [suggestions, setSuggestions] = useState<suggestionType[]>([])
  const [suggestionsSelected, setSuggestionsSelected] = useState<suggestionType[]>([])
  const [inputValueModal, setInputValueModal] = useState(selectedCardGoal + new Date().toLocaleDateString())
  const [webAdGroups, setWebAdGroups] = useState<any>([])
  const [selectedStartDate, setSelectedStartDate] = useState()
  const [selectedEndDate, setSelectedEndDate] = useState()
  const [checkOptions, setCheckOptions] = useState(["Desktop", "Mobile App", "Mobile Web"])
  const [open, setOpen] = React.useState(false)
  const [selectedCardChanel, setSelectedCardChanel] = useState("Web")
  const [draft, setDraft] = useState(false)
  const [webAds, setWebAds] = useState<WebAdsType[]>([])
  const [checkStartTimeFlag, setCheckStartTimeFlag] = useState(1)
  const [warningNumber, setWarningNumber] = useState()

  const history = useHistory()

  const handleNumberChange = (event: any) => {
    const newValue = event
    setInputNumberValue(newValue)
    setWarningNumber(newValue)
  }

  const handleCampStatus = (value: string) => {
    if (value === "Live") {
      setDraft(false)
    } else {
      setDraft(true)
    }
  }

  const dataForContextualTargets = [
    "Arts & Entertainment",
    "Automotive",
    "Business & Industry",
    "Careers",
    "Dating",
    "Education",
    "Family & Parenting",
    "Fashion & Beauty",
    "Finance",
    "Food & Drink",
    "Health & Fitness",
    "Home & Garden"
  ].map((item) => ({
    label: item,
    value: item
  }))

  const handleOpen = () => {
    setOpen(true)
  }

  const handleCancel = () => {
    localStorage.setItem("selectedMenuItem", "create-ads")
    window.location.href = "/ads"
  }

  const cardGoaldata = ["Re-targeting", "Lookalike Campaign", "Contextual Campaign"].map((item) => ({
    label: item,
    value: mapLabelToValue(item)
  }))

  function mapLabelToValue(label: string) {
    switch (label) {
      case "Re-targeting":
        return "retargeting"
      case "Lookalike Campaign":
        return "lookalike"
      case "Contextual Campaign":
        return "contextual"
      default:
        return label
    }
  }

  const handleClose = () => setOpen(false)

  const handleNameChange = (event: any) => {
    const newValue = event
    setInputValue(newValue)
  }

  const handleBidInput = (event: any) => {
    const newValue = event
    setBidInput(newValue)
  }

  const handleSelectChange = (value: any) => {
    setSelectedValue(value)
  }

  const handleSelectedRowsChange = (selectedRows: any) => {
    setSelectedRowsImg(selectedRows)
  }

  const handleRowSelectedAud = (state: any) => {
    setSelectedRowsAud(state.selectedRows)
  }

  const handleContextualTargetsChange = (selectedValues: any[]) => {
    setWebAdGroups(selectedValues)
  }

  const deviceTargets = {
    targets_desktop: checkOptions.includes("Desktop"),
    targets_mobile_web: checkOptions.includes("Mobile Web"),
    targets_mobile_app: checkOptions.includes("Mobile App")
  }

  const handleSubmit = async () => {
    setLoading(true)
    const todaysDate = new Date().toISOString().split("T")[0] + "T" + new Date().toLocaleTimeString(undefined, { hour12: false })

    if (webAds.length === 0 && !draft) {
      Alert.error("Please choose your preferable Ad from ad-library")
      setLoading(false)
      return
    }

    try {
      let payload
      if (selectedCardGoal === "retargeting" && selectedCardChanel === "Web") {
        if (selectedRowsAud.length === 0 && !draft) {
          Alert.error("Please choose your preferable audience")
          setLoading(false)
          return
        }
        payload = {
          name: inputValueModal,
          budget_balancing_automated: true,
          start_date: checkStartTimeFlag === 2 ? selectedStartDate : todaysDate,
          end_date: todaysDate === selectedStartDate ? "" : selectedEndDate,
          is_draft: draft,
          currency: "USD",
          budget: {
            type: "daily",
            goal: inputNumberValue
          },
          campaigns: [
            {
              name: inputValueModal,
              service: service,
              adgroups: [
                {
                  name: inputValue,
                  service: service,
                  ads: webAds.map((selectedRowsImgEach) => {
                    return {
                      eid: selectedRowsImgEach.adroll_ad_eid,
                      status: "live"
                    }
                  }),
                  geotargets:
                    suggestionsSelected.length !== 0
                      ? suggestionsSelected.map((suggestion) => ({
                          eid: suggestion,
                          targeted: true
                        }))
                      : [],

                  segments: selectedRowsAud.map((selectedRowsImgEach) => {
                    return {
                      eid: selectedRowsImgEach.segment_id,
                      targeted: true
                    }
                  }),
                  device_targets: deviceTargets
                }
              ]
            }
          ]
        }

        if (bidInput !== undefined) {
          payload.campaigns = payload.campaigns.map((campaign) => ({
            ...campaign,
            kpi: {
              metric: bidStrategyValue,
              goal: bidInput
            }
          }))
        }
      } else if (selectedCardGoal === "lookalike" && selectedCardChanel === "Web") {
        if (selectedRowsAud.length === 0 && !draft) {
          Alert.error("Please choose your preferable audience")
          setLoading(false)
          return
        }

        payload = {
          name: inputValueModal,
          budget_balancing_automated: true,
          start_date: checkStartTimeFlag === 2 ? selectedStartDate : todaysDate,
          end_date: todaysDate === selectedStartDate ? "" : selectedEndDate,
          is_draft: draft,
          currency: "USD",
          budget: {
            type: "daily",
            goal: inputNumberValue
          },
          campaigns: [
            {
              name: inputValueModal,
              service: service,
              key: "2",
              adgroups: [
                {
                  name: inputValue,
                  service: service,
                  campaign_eid: "2",
                  ads: webAds.map((selectedRowsImgEach) => {
                    return {
                      eid: selectedRowsImgEach.adroll_ad_eid,
                      status: "live"
                    }
                  }),
                  flights: [],
                  geotargets:
                    suggestionsSelected.length !== 0
                      ? suggestionsSelected.map((suggestion) => ({
                          eid: suggestion,
                          targeted: true
                        }))
                      : [],
                  auto_audience: true,
                  auto_geo_targets: suggestions.length !== 0 ? false : true,
                  device_targets: deviceTargets
                }
              ],
              targeting_type: selectedCardGoal
            }
          ]
        }
        if (bidInput !== undefined) {
          payload.campaigns = payload.campaigns.map((campaign) => ({
            ...campaign,
            kpi: {
              metric: bidStrategyValue,
              goal: bidInput
            }
          }))
        }
      } else if (selectedCardGoal === "contextual" && selectedCardChanel === "Web") {
        if (webAdGroups.length === 0) {
          Alert.error("Please select contextual target")
          return
        }
        payload = {
          name: inputValueModal,
          budget_balancing_automated: true,
          start_date: checkStartTimeFlag === 2 ? selectedStartDate : todaysDate,
          end_date: todaysDate === selectedStartDate ? "" : selectedEndDate,
          is_draft: draft,
          currency: "USD",
          budget: {
            type: "daily",
            goal: inputNumberValue
          },
          campaigns: [
            {
              name: inputValueModal,
              service: service,
              key: "2",
              adgroups: [
                {
                  name: inputValue,
                  service: service,
                  campaign_eid: "2",
                  ads: webAds.map((selectedRowsImgEach) => {
                    return {
                      eid: selectedRowsImgEach.adroll_ad_eid,
                      status: "live"
                    }
                  }),
                  flights: [],
                  geotargets:
                    suggestionsSelected.length !== 0
                      ? suggestionsSelected.map((suggestion) => ({
                          eid: suggestion,
                          targeted: true
                        }))
                      : [],
                  auto_audience: true,
                  auto_geo_targets: suggestions.length !== 0 ? false : true,
                  contextual_targets: [
                    {
                      name: "attributes",
                      source: "clouseau_ct_nxroll_tier2",
                      options: webAdGroups.map((webAdGroupsIndex: any) => {
                        return {
                          name: webAdGroupsIndex,
                          target: true
                        }
                      })
                    }
                  ],
                  device_targets: deviceTargets
                }
              ],
              targeting_type: selectedCardGoal
            }
          ]
        }
        if (bidInput !== undefined) {
          payload.campaigns = payload.campaigns.map((campaign) => ({
            ...campaign,
            kpi: {
              metric: bidStrategyValue,
              goal: bidInput
            }
          }))
        }
      }

      const providerId = await useProviderId()

      const campSubmit = await retargetingCamp(providerId, payload)

      if (campSubmit.status === 201 || campSubmit.status === 200) {
        Alert.success("Campaign create successfully")
        localStorage.setItem("selectedMenuItem", "campaign-list")
        history.push("/ads")
        localStorage.setItem("selectedMenuItem", "campaign-list")
      } else {
        Alert.error("error")
      }
    } catch (error) {
      Alert.error("Error in API request")
    } finally {
      setLoading(false)
    }
  }

  const handleModalInputChange = (event: any) => {
    const newValue = event
    setInputValueModal(newValue)
  }

  useEffect(() => {
    if (selectedCardGoal === "retargeting") {
      setService("web-retargeting")
    } else if (selectedCardGoal === "lookalike") {
      setService("web-prospecting")
    } else if (selectedCardGoal === "contextual") {
      setService("web-prospecting")
    }
  }, [selectedCardGoal])

  useEffect(() => {
    if (selectedValue === "Impressions") {
      setBidStrategyValue("CPM")
    } else if (selectedValue === "Conversion") {
      setBidStrategyValue("CPA")
    } else if (selectedValue === "Clicks") {
      setBidStrategyValue("CPC")
    }
  }, [selectedValue])

  useEffect(() => {
    setInputValueModal(selectedCardGoal + " Campaign " + new Date().toLocaleDateString())
  }, [selectedCardGoal])

  return (
    <>
      <Content className='main_content'>
        <Header>
          <div className='cardTitle'>
            <div>
              <p style={{ color: "#8c8c8c", fontSize: "16px" }}>
                {" "}
                {selectedCardGoal === "retargeting"
                  ? "Re-targeting"
                  : selectedCardGoal === "lookalike"
                    ? "Lookalike Campaign"
                    : selectedCardGoal === "contextual"
                      ? "Contextual Campaign"
                      : ""}
              </p>

              <div className='titleEdit'>
                <p className='titleCampHeader'>{inputValueModal}</p>

                <div onClick={handleOpen} className='monty-proxy-edit-name'>
                  Edit Name
                </div>
              </div>
            </div>
          </div>
        </Header>
        <div className='__dashboard_container'>
          <div className='addRoll__container-area'>
            <Content className='addRoll__container-area-content'>
              <div className='show-grid'>
                <FlexboxGrid justify='center'>
                  <FlexboxGrid.Item colspan={20}>
                    <BoxPanel
                      boxSubTitle={"Web"}
                      selectedCardChanel={"Web"}
                      setSelectedCardChanel={setSelectedCardChanel}
                      webFlag={"webFlag"}
                      handleOpen={handleOpen}
                      imgS={channelSvg}
                      title={"Campaign Settings"}
                      boxBody={"ChannelsBody"}
                      boxtitle={"Channels"}
                      handleModalInputChange={handleModalInputChange}
                      buttonToolbar={
                        <div className='settingsBox'>
                          <img src={settingsIcon} alt='s' style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                        </div>
                      }
                      selectedCardGoal={selectedCardGoal}
                      setInputValueModal={setInputValueModal}
                    />
                  </FlexboxGrid.Item>
                </FlexboxGrid>
              </div>

              <div className='show-grid'>
                <FlexboxGrid justify='center'>
                  <FlexboxGrid.Item colspan={20}>
                    <BoxPanel
                      imgS={chooseScheduleSvg}
                      boxSubTitle={"Starts immediately upon approval, no end date"}
                      boxtitle={"Choose Schedule"}
                      boxBody={"ScheduleBody"}
                      loading={loading}
                      setLoading={setLoading}
                      setSelectedStartDate={setSelectedStartDate}
                      setSelectedEndDate={setSelectedEndDate}
                      nextFiveDays={nextFiveDays}
                      setCheckStartTimeFlag={setCheckStartTimeFlag}
                    />
                  </FlexboxGrid.Item>
                </FlexboxGrid>
              </div>

              <div className='show-grid' style={{ paddingTop: "2%" }}>
                <FlexboxGrid justify='center'>
                  <FlexboxGrid.Item colspan={20}>
                    <BoxPanel
                      imgS={webSvg}
                      boxSubTitle={"Optimized for conversions, automatic CPA"}
                      title={"Web Channel"}
                      boxtitle={"Choose Web Settings"}
                      boxBody={"webSettingsBody"}
                      handleBidInput={handleBidInput}
                      selectedValue={selectedValue}
                      handleSelectChange={handleSelectChange}
                      selectedCardGoal={selectedCardGoal}
                    />
                  </FlexboxGrid.Item>
                </FlexboxGrid>
              </div>

              <div className='show-grid'>
                <FlexboxGrid justify='center'>
                  <FlexboxGrid.Item colspan={20}>
                    <BoxPanel
                      imgS={adgroupSvg}
                      boxSubTitle={"Ad group settings, audience and ads"}
                      boxtitle={"Configure AdGroup"}
                      boxBody={"ConfigureBody"}
                      handleNameChange={handleNameChange}
                      inputValue={inputValue}
                      handleSelectedRowsChange={handleSelectedRowsChange}
                      selectedRowsImg={selectedRowsImg}
                      handleRowSelectedAud={handleRowSelectedAud}
                      setSuggestions={setSuggestions}
                      setSuggestionsSelected={setSuggestionsSelected}
                      suggestions={suggestions}
                      selectedCardGoal={selectedCardGoal}
                      dataForContextualTargets={dataForContextualTargets}
                      handleContextualTargetsChange={handleContextualTargetsChange}
                      loading={loading}
                      setLoading={setLoading}
                      setCheckOptions={setCheckOptions}
                      selectedRowsAud={selectedRowsAud}
                      setWebAds={setWebAds}
                      setSelectedRowsAud={setSelectedRowsAud}
                    />
                  </FlexboxGrid.Item>
                </FlexboxGrid>
              </div>

              <div className='show-grid' style={{ paddingTop: "2%" }}>
                <FlexboxGrid justify='center'>
                  <FlexboxGrid.Item colspan={20}>
                    <BoxPanel
                      imgS={budget}
                      boxSubTitle={"Smart Budget optimization across channels or separate budgets"}
                      boxtitle={"Choose Budget"}
                      title={"Campaign Budget"}
                      boxBody={"ChooseBudgetBody"}
                      handleNumberChange={handleNumberChange}
                      inputNumberValue={inputNumberValue}
                      warningNumber={warningNumber}
                    />
                  </FlexboxGrid.Item>
                </FlexboxGrid>
              </div>
            </Content>
          </div>
        </div>
        <div className='finishBtnTop'>
          <ButtonToolbar>
            <Button appearance='default' className='cancelBtn' onClick={handleCancel}>
              Cancel
            </Button>
            <Button className='finishBtn' disabled={loading} onClick={handleSubmit}>
              {loading ? "Finishing..." : "Finish"}
            </Button>
          </ButtonToolbar>
        </div>
      </Content>

      <Modal className='__generic_modalManageCamp' show={open} onHide={handleClose}>
        <Modal.Header className='modal-header-custom'>
          <Modal.Title className='title'>Manage Campaign</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal__body_camp' style={{ padding: "0px 20px" }}>
          <div>
            <p className='titleCamp'>Campaign Name</p>
            <Input defaultValue={inputValueModal} onChange={handleModalInputChange} />
            <br />
            <p className='titleCamp'>Campaign Type</p>
            <SelectPicker size='md' appearance='default' defaultValue={selectedCardGoal} disabled cleanable={false} data={cardGoaldata} />
            <div style={{ marginTop: "4%", display: "flex", alignItems: "center" }}>
              <p className='titleCamp'>Campaign Status : </p>

              <RadioGroup name='radioList' inline defaultValue={draft === false ? "Live" : "Draft"} onChange={handleCampStatus}>
                <Radio value='Live'>
                  <p style={{ fontWeight: "500" }}>Live</p>
                </Radio>
                <Radio value='Draft'>
                  <p style={{ fontWeight: "500" }}>Draft</p>
                </Radio>
              </RadioGroup>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ padding: "20px" }}>
          <Button onClick={handleClose} style={{ backgroundColor: "#0fae8c", color: "white", padding: "8px 28px" }}>
            Ok
          </Button>
          <Button onClick={handleClose} color='red' appearance='primary'>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AddRoll
